import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import NormalPageBlock from "../components/normal-page-block";

class DPADCalendar extends Component {
    render() {
        return (
            <Layout headerElement={<NormalPageBlock siteTitle="DPAD Calendar" />}>
                <SEO title="DPAD Calendar" />
            </Layout>
        );
    }
}

export default DPADCalendar

